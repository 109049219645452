import React from 'react'
import pic03 from '../assets/images/pic03.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>Goal Driven</h2>
          <p>Accountability Matters</p>
        </header>
        <p>
          In many medical settings the nature of the process is to react.
        </p>
        <p>
          However to truly solve a problem and succeed requires one to act on a plan that reaches a solution.
        </p>
        <ul className="actions">
          <li>
            <ScrollLink
              to="four"
              className="button"
              activeClass="active"
              smooth={true}
              offset={0}
              duration={1500}
              spy={true}
            >
              Learn More
    </ScrollLink>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
