import React from 'react'
import Fade from 'react-reveal/Fade'

const Four = props => (
  <Fade up>
    <section id="four" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2>Enterprise Management Optimization</h2>
          <p>
            We consistenly seek to improve our key business units through a combination of cross-cutting solutions.
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-chart-area"></span>
              <h3>Workflow automation</h3>
              <p>
                Both offline and online processes in today's world are now targets of automation.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-comment"></span>
              <h3>Automated Evaluation</h3>
              <p>
              Triggered and scheduled work, processes and customer reviews all improve the enterprise.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-flask"></span>
              <h3>Recurring Audit</h3>
              <p>
                Great work and hidden improvements can only be discovered through proper oversight.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-paper-plane"></span>
              <h3>Stress Testing</h3>
              <p>
                Systems and methods that work well at one level must also work well when demand kicks in.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-file"></span>
              <h3>Process Role Reversal</h3>
              <p>
                Empathy in the workplace will consistently yield a just and productive environment.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-lock"></span>
              <h3>Evergreen Valuations</h3>
              <p>
              The value of any process, division or enterprise as a whole must always remain in view.
              </p>
            </section>
          </div>
        </div>
        {/* <footer className="major">
          <ul className="actions special">
            <li>
              <a href="/" className="button">
                Magna sed feugiat
              </a>
            </li>
          </ul>
        </footer> */}
      </div>
    </section>
  </Fade>
)

export default Four
