import React from 'react'
import Fade from 'react-reveal/Fade'
import { StaticQuery, graphql } from 'gatsby'
const Five = props => (
  <section id="five" className="wrapper style2 special fade inactive">
    <Fade duration={2500}>
      <div className="container">
        <header>
        <StaticQuery
    query={graphql`
      query SiteAddressQuery {
        site {
          siteMetadata {
            legalName,
            address {
              street,
              city,
              state,
              zip
            },
            phone {
              directDial
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
          <h2>{data.site.siteMetadata.legalName}</h2>
          <p>{data.site.siteMetadata.address.street}<br/>{data.site.siteMetadata.address.city}, {data.site.siteMetadata.address.state} {data.site.siteMetadata.address.zip}<br/><br/>
          {data.site.siteMetadata.phone.directDial}</p>
      </React.Fragment>
    )}
  />
        </header>

      </div>
    </Fade>
  </section>
)
export default Five
